.portfolio-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 3rem;
}
.portfolio-item{
    background: var(--color-bg-variant);
    padding: 1.2rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
}
.portfolio-item:hover {
    border-color: var(--color-primary-variant);
    background: transparent;
    cursor: default;
}
.portfolio-item-image{
    border-radius: 1.5rem;
    aspect-ratio: 1.5/1;
    overflow: hidden;
}
.portfolio-item h3{
    margin: 1.2rem 0 2rem;
}
.portfolio-item-cta{
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
}
/* =================== MEDIA QUERIES (MEDIUM SIZED DEVICES) ====================  */

@media screen and (max-width: 1024px) {
    .portfolio-container{
        gap: 0.9rem;
    }
}
/* =================== MEDIA QUERIES (SMALL SIZED DEVICES) ====================  */

@media screen and (max-width: 600px) {
    .portfolio-container{
        grid-template-columns: 1fr;
        gap: 0.7rem;
    }
    .portfolio-item{
        width: 95%;
        margin: auto;
    }
}