.container.contact-container{
    width: 58%;
    display: grid;
    grid-template-columns: 30% 58%;
    gap: 12%;
}
.contact-options{
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}
.contact-option{
    background: var(--color-bg-variant);
    padding: 1.2rem;
    border-radius: 1.2rem;
    text-align: center;
    border: 1px solid transparent;
    transition: var(--transition);
}
.contact-option:hover {
    background: transparent;
    border-color:var(--color-primary-variant) ;
}
.contact-option-icon{
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}
.contact-option a{
    margin-top: 0.7rem;
    display: inline-block;
    font-size: 0.8rem;
}
.contact-option h5{
    color: var(--color-light);
}

/* ====================FORM================ */
form{
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}
input, textarea{
    width: 100;
    padding:1.5rem;
    border-radius: 0.5rem;
    background: transparent;
    border: 2px solid var(--color-primary-variant);
    resize: none;
    color: var(--color-white);

}
@media screen and (max-width: 1024px) {
    .container.contact-container{
        grid-template-columns: 1fr;
        gap: 2rem;
    }
}
/* =================== MEDIA QUERIES (SMALL SIZED DEVICES) ====================  */

@media screen and (max-width: 600px) {
    .container.contact-container{
        width: var(--container-width-sm);
        gap: 2rem;
    }
    input, textarea{
        width: 96%;
        margin: auto;
    }
}